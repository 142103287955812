import { assign, createMachine } from "xstate";

export interface JobCardContext {
  copy: string,
  query: string | undefined
}

export type JobCardEvents = 
  | { type: "SYNC_STATE", query: string | undefined, rawCopy: string | null | undefined}

export const machine = createMachine<JobCardContext, JobCardEvents>({
  predictableActionArguments: true,
  id: "job-card",
  initial: "idle",
  context: {
    copy: "",
    query: undefined
  },
  states: {
    idle: {
      on: {
        SYNC_STATE: {
          actions: [
            assign({
              copy: ({}, {query, rawCopy}) => {
                if (query && query.length > 0 && rawCopy && rawCopy.length > 0) {
                  const lowerCopy = rawCopy.toLowerCase()
                  if (lowerCopy.indexOf(query) >= 0) {
                    let i = 0
                    let newI = lowerCopy.indexOf(query, i)
                    let newCopy = ""
                    while(newI >= 0) {
                      newCopy += rawCopy.substring(i, newI)
                      newCopy += "<b>" + rawCopy.substring(newI, newI + query.length) + "</b>"
                      i = newI + query.length
                      newI = lowerCopy.indexOf(query, i)
                    }
                    newCopy += rawCopy.substring(i)
                    return newCopy
                 }
                }
                return rawCopy ?? ""
              },
            })
          ]
        }
      },
    },
  },
},
{
});

export default machine