import { useEffect, useRef, useState } from 'react'
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import { trackNavigation } from 'src/utils/tracking'
import useIntentionalHover from 'utils/useIntentionalHover'
import Icon from '../Icons/Icon'

type MainNavSubItemProps = {
  title?: Field<string>
  path?: string
  type?: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
  isExperience?: boolean
  isSelected?: boolean
  isParent?: boolean
  isSmall?: boolean
  isHovered?: boolean
}

const MainNavSubItem = ({
  title,
  path,
  onMouseOver,
  onMouseLeave,
  onClick,
  isExperience,
  isSelected,
  isParent,
  isSmall = false,
  isHovered,
}: MainNavSubItemProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const [shieldUp, setShieldUp] = useState(isHovered)
  useEffect(() => {
    setShieldUp(isHovered)
  }, [isHovered])

  const intentionalHoverRef = useIntentionalHover<HTMLAnchorElement>({
    onHover: onMouseOver!,
    onLeave: onMouseLeave,
    velocityThreshold: 0.02,
    durationThreshold: 50,
  })

  const intentionalShieldHoverRef = useIntentionalHover<HTMLDivElement>({
    onHover: () => setShieldUp(false),
    velocityThreshold: 0.02,
    durationThreshold: 50,
  })

  const itemElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = itemElement?.current?.getBoundingClientRect().left || 0
    const width = itemElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < width / 2) finalX = 0
    if (finalX > width / 2) finalX = width

    setMousePosition({ left: finalX })
  }

  const itemClasses = `

        main-nav-sub-item group/subitem relative flex justify-between shrink-0 items-center
        text-grey
        ${isHovered ? 'z-50' : ''}
        ${!isSmall ? 'mr-5 w-[570px] xxl:w-[660px]' : 'w-full max-w-[450px]'}
    `
  const titleClasses = `
        ${isSmall ? 'text-[26px] leading-[36px] font-uniform-condensed' : ''} mr-auto
        [.light_&]:text-grey-medium
        transition duration-300
        inline-flex relative
    `
  const lgTitleClasses = `
        py-[18px] text-10x h3
        group-hover/sub:text-black-off
    `
  const smTitleClasses = `
        py-3 text-[26px]
        group-hover/subitem:text-black-off
    `
  const fillClasses = `
    main-nav-item-active z-0 absolute bottom-2 w-full ${isSmall ? 'h-[3px]' : 'h-1'}
    bg-black-off
    scale-x-0 group-hover/subitem:scale-x-100 group-[.is-activex]/subitem:scale-x-100
    transition duration-400 delay-150 ease-linear
`

  return (
    <>
      {isHovered && shieldUp && (
        <div className="pointer-events-none absolute -inset-y-72 inset-x-0 z-40" ref={intentionalShieldHoverRef}>
          <svg viewBox="0 0 200 100" className="group/subshield pointer-events-none absolute inset-0 h-full w-full">
            <polygon points="0,50 150,0 200,0 200,100 150,100" fill="transparent" className="pointer-events-auto" />
          </svg>
        </div>
      )}
      <Link
        onMouseMove={(ev) => handleMouseMove(ev)}
        href={path || '/'}
        tabIndex={0}
        ref={intentionalHoverRef}
        onMouseOver={() => setShieldUp(true)}
        onFocus={() => onMouseOver?.()}
        onClick={() => {
          onClick?.()
          trackNavigation({
            location: 'header',
            path: path?.replace(/^\//, '').replace('/', ' - ') as string,
          })
        }}
        className={itemClasses}
      >
        {isExperience ? (
          <Text className={`lbody block ${isSelected ? 'text-white' : 'text-grey-medium'}`} field={title} />
        ) : (
          <span ref={itemElement} className={`${isSmall ? smTitleClasses : lgTitleClasses} ${titleClasses}`}>
            {title?.value}
            <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
          </span>
        )}
        {isParent && (
          <>
            <span className="ml-10 mr-4">
              <Icon name="chevron-right" type="stroke" width={45} height={45} />
            </span>
            <div className="absolute inset-y-0 -right-8 z-10 -my-12 hidden w-32  [clip-path:polygon(100%_0%,0%_50%,100%_100%)] group-hover/subitem:block"></div>
          </>
        )}
      </Link>
    </>
  )
}

export default MainNavSubItem
