import React, { useEffect, useRef, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import FocusTrap from 'focus-trap-react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'

import usePageContext from 'components/front-end/_layoutContext'
import BreadCrumbs from 'components/front-end/BreadCrumbs/BreadCrumb'
import { NavigationItem, NavigationProps } from 'components/front-end/Navigation/_interface'
import MainNavAction from 'components/front-end/Navigation/MainNavAction'
import MainNavBack from 'components/front-end/Navigation/MainNavBack'
import MainNavCall from 'components/front-end/Navigation/MainNavCall'
import MainNavIcon from 'components/front-end/Navigation/MainNavIcon'
import MainNavItem from 'components/front-end/Navigation/MainNavItem'
import MainNavMobile from 'components/front-end/Navigation/MainNavMobile'
import MainNavSubItem from 'components/front-end/Navigation/MainNavSubItem'
import { useIntersect } from 'src/utils/intersection'
import useScrollLock from 'src/utils/scrollLock'
import { throttle } from 'src/utils/throttle'
import { trackNavigation } from 'src/utils/tracking'
import { useGreaterThan, useLessThan } from 'src/utils/useBreakpoints'
import logoDark from '../../public/logos/logo-adfa-careers-dark.svg'
import logoLight from '../../public/logos/logo-adfa-careers-light.svg'
import QuickLink from './front-end/QuickLinks/QuickLink'
import SearchTakeover from './front-end/Search/SearchTakeover'
import ShortlistNavAction from './front-end/Shortlist/ShortlistNavAction'

const ADFNavigation = (props: NavigationProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()

  const isEdit = sitecoreContext?.pageState === 'edit'

  const pageState = usePageContext().pageContext

  const { lockScroll, unlockScroll } = useScrollLock()

  const [levels, setLevels] = useState({ level1: '', level2: '', level3: '' })
  const [firstNav, setFirstNav] = useState<NavigationItem | undefined>(undefined)
  const [secondNav, setSecondNav] = useState<NavigationItem | undefined>(undefined)
  const [thirdNav, setThirdNav] = useState<NavigationItem | undefined>(undefined)

  const [navActive, setNavActive] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [navInAnim, setNavInAnim] = useState(true)
  const [navOutAnim, setNavOutAnim] = useState(false)

  const [subNavActive, setSubNavActive] = useState(false)

  const [firstSubChild, setFirstSubChild] = useState(true)

  const router = useRouter()
  const pathname = usePathname()
  useEffect(() => {
    router.events.on('routeChangeComplete', deactivateNav)
    return () => {
      router.events.off('routeChangeComplete', deactivateNav)
    }
  }, [router.events])

  // Save the hostname for history on initial load
  useEffect(() => {
    pageState?.history.checkIsOwnPath()
  }, [router.asPath, pageState?.history])

  const ssrNavRef = useRef<HTMLDivElement>(null)

  const navNodeRef = useRef<HTMLDivElement>(null)
  const focusTrapContainerRef = useRef<HTMLDivElement>(null)

  const entry = useIntersect(navNodeRef, '0px 0px 500px 0px')
  // If entry is null, assume we are intersecting as we are initialising
  const headerEntry = entry ? entry.intersectionRatio * 100 : 1000

  const [pageUp, setPageUp] = useState(true)
  const lastScroll = useRef(0)

  const checkScrollTop = () => {
    const currentScroll = window.pageYOffset // Not deprecated MDN
    setPageUp(currentScroll < 1 || lastScroll.current > currentScroll)
    lastScroll.current = currentScroll
  }

  useEffect(() => {
    pageState?.pageScroll?.setScrollDirection(pageUp)
  }, [pageUp, pageState?.pageScroll])

  // useCallback breaks navigation coming back when scroll up
  const throttledFunc = throttle(checkScrollTop, 500)

  useEffect(() => {
    checkScrollTop()
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', throttledFunc, false)
      return () => {
        window.removeEventListener('scroll', throttledFunc)
      }
    } else {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // throttledFunc in deps breaks navigation coming back when scroll up
  // Rather than just add the deps, look at how this can be ritten differently

  useEffect(() => {
    if (headerEntry > 93) pageState?.header?.setIsStickyHeader(false)
    else if (headerEntry <= 92) {
      pageState?.header?.setIsStickyHeader(true)
    }
  }, [headerEntry, pageState?.header, pageUp])

  useEffect(() => {
    const path = pathname.replace(/^\/+|\/+$/g, '').split('/')
    setLevels(getLevels(path))
  }, [pathname])

  function getLevels(path: string[]): { level1: string; level2: string; level3: string } {
    const level1 = `/${path?.[0] ?? ''}`
    const level2 = `/${path?.[0] ?? ''}/${path?.[1] ?? ''}`
    const level3 = `/${path?.[0] ?? ''}/${path?.[1] ?? ''}/${path?.[2] ?? ''}`

    return { level1, level2, level3 }
  }

  function deactivateNav() {
    setNavActive(false)
    setSubNavActive(false)
  }

  function deactivateSubNav() {
    setSubNavActive(false)
  }

  useEffect(() => {
    if (navActive) {
      lockScroll()
      // Hide search if nav is active
      setSearchActive(false)
    } else {
      unlockScroll()
    }

    if (!navInAnim && !navActive) setNavOutAnim(true)
    setTimeout(() => {
      if (!navActive) {
        setFirstNav(undefined)
        setSecondNav(undefined)
        setThirdNav(undefined)
        setNavInAnim(true)
        setNavOutAnim(false)
      } else {
        setNavInAnim(false)
      }
    }, 300)
  }, [navActive, lockScroll, navInAnim, unlockScroll])

  useEffect(() => {
    if (searchActive) {
      lockScroll()
      // Hide nav is search is active
      setNavActive(false)
      setSubNavActive(false)
    } else {
      unlockScroll()
    }
  }, [lockScroll, searchActive, unlockScroll])

  useEffect(() => {
    ssrNavRef?.current?.remove()
    // Improve tab accessibility on mobile menu when on desktop
    const focusParent = document.querySelector('.main-nav-link') as HTMLAnchorElement
    if (focusParent && !firstNav) focusParent.focus()
  }, [secondNav, firstNav])

  // Fix to hide arrow on Parent if all Items are Hidden below
  const hasVisibleChildren = (itemList: NavigationItem[] | undefined) => {
    if (!!itemList?.length && !itemList?.find((x: NavigationItem) => x.hideInNavigation?.value?.value === true))
      return true
    return false
  }

  const navigationWrapperClasses = `
    ${navActive ? 'light' : ''}
    nav-wrapper navigation-main relative
    bg-white [.light_&]:bg-white [.dark_&]:bg-black-off [.grey_&]:bg-black/60
    border-b border-grey-light [.light_&]:border-grey-light [.dark_&]:border-grey-medium
    transition duration-500
  `
  const navigationContainerClasses = `
    nav-container w-full max-w-full navigation-grid flex h-16 xl:h-22
  `
  const logoWrapperClasses = `
    logo-wrapper relative flex items-center flex-1 lg:flex-none
    pl-6 xm:pl-0
    sm:pr-[22px] [@media(min-width:1700px)]:pr-8
    mr-2.5 [@media(min-width:1700px)]:mr-3
    lg:border-r border-grey-light [.light_&]:border-grey-light [.dark_&]:border-grey-medium
    transition duration-300
  `
  const logoBackClasses = `
    logo-back absolute flex items-center xm:static top-0 left-0 w-full h-full pl-6 xm:pl-0
    ${navActive ? 'animate-reveal-in-right visible' : 'invisible'}
  `
  const navPageMaskClasses = `
    nav-mask absolute top-16 xl:top-22 left-0 z-50
    h-[calc(100vh_-_64px)] xl:h-[calc(100vh_-_88px)] w-full
    bg-black/60 hidden md:block
    ${navActive ? 'animate-fade-in' : 'animate-fade-out'}
  `
  const firstLevelClasses = `
    first-level fixed top-16 xl:top-22 left-0 z-50
    h-[calc(100vh_-_64px)] xl:h-[calc(100vh_-_88px)]
    border-t border-grey-light
    py-24 overflow-y-auto bg-white
    transition-[width,min-width] duration-300
    animation-delay-[200ms]
    ${
      subNavActive
        ? 'w-3/4 xl:w-[67.5%] min-w-[70rem]'
        : 'w-[58.3333%] min-w-[53rem] xl:w-[940px] [@media(min-width:1700px)]:w-[1000px] xxl:w-[1060px]'
    }
    ${navInAnim ? 'animate-reveal-in' : ''}
    ${navOutAnim ? 'animate-reveal-out' : ''}

    flex flex-col
  `
  const firstQuickLinksClasses = `
    first-quick light mt-auto mb-auto md:mb-0
    pr-5 w-[590px] xxl:w-[660px] max-w-full
  `
  const quickLinksClasses = `
    [&>a>div]:mb-0 [&>a>div]:mt-5 [&>a]:block
  `
  const secondLevelClasses = `
    second-level fixed top-16 xl:top-22 left-[650px] xxl:left-[760px] z-40
    h-[calc(100vh_-_63px)] xl:h-[calc(100vh_-_87px)]
    border-t border-grey-light
    overflow-y-auto
    w-[30%] opacity-0 pointer-events-none
    py-24 px-[30px] xxl:pl-[60px]
    bg-white
    transition duration-[180ms] ease-in-out group-hover/sub:delay-200
    translate-x-[-5%]
    group-hover/sub:translate-x-0 group-hover/sub:opacity-100 group-hover/sub:pointer-events-auto
    group-focus-within/sub:translate-x-0 group-focus-within/sub:opacity-100 group-focus-within/sub:pointer-events-auto
    ${firstSubChild ? '[&_a:first-child]:text-black-off' : ''}
  `
  const subNavContainer = {
    paddingLeft: 'var(--outermargin)',
    paddingRight: 'calc(var(--outermargin) / 2)',
  }
  const gradientClasses = `
    nav-gradient absolute top-100 h-[103px] w-full bg-gradient-to-b from-black/60 pointer-events-none
    transition duration-500
    opacity-0 [.grey_&]:opacity-100
  `
  const navigationActionsClasses = `
    nav-actions flex ml-auto
    xm:border-r border-grey-light [.light_&]:border-grey-light [.dark_&]:border-grey-medium
    transition duration-300
  `
  const navMobileWrapperClasses = `
    overflow-y-auto
    mobile-wrapper absolute right-0 w-full md:w-1/2 lg:w-5/12
    top-16 h-[calc(100vh_-_64px)] xl:top-22 xl:h-[calc(100vh_-_88px)]
    bg-white z-[60]
    border-t border-grey-light [.light_&]:border-grey-light [.dark_&]:border-grey-medium [.grey_&]:border-white
    ${navActive ? 'visible opacity-100' : 'invisible opacity-0'}
  `
  const navMobileClasses = `
    mobile-nav relative md:h-full
    ${navActive ? 'animate-reveal-in' : ''}
  `
  const navMobileInnerClasses = `
    navigation-grid pl-6 pr-4 pt-[26px]
    h-full flex flex-col bg-white
  `
  const navMobileSubClasses = `
    mobile-subnav absolute z-40 top-0 right-0 w-full h-full pb-16 bg-white
    ${navActive ? 'animate-reveal-in' : ''}
  `
  const navMobilePinned = `
    mobile-actions-pinned absolute bottom-0 left-0 z-20 bg-white
    border-t border-grey-light md:hidden
    flex [&>a]:flex-1 [&>button]:flex-1 h-16 w-full
  `
  const stickyObserver = `
    sticky-observer  top-0 right-0 w-full
    h-[74px] xl:h-[98px]
    -mb-2.5
  `
  const breadCrumbsClasses = `
    breadcrumbs
    overflow-hidden
    max-h-[110px] [.no-breadcrumb_&]:max-h-0
    transition-all duration-300
  `
  const headerGapClasses = `
    header-gap h-16 xm:h-[70px] lg:h-[108px] [.no-breadcrumb_&]:h-0
    overflow-hidden
    transition-all duration-300
  `
  const setStickyNav = `w-full ${
    pageState?.header.isStickyHeader ? 'fixed left-0 -top-16 xl:-top-22 transition duration-[500ms] delay-0' : ''
  }`
  const revealNavClasses = `translate-y-16 xl:translate-y-22`

  const lessThanXm = useLessThan('xm')
  const lessThanLg = useLessThan('lg')
  const lessThanNav = useLessThan('nav')
  const greaterThanMd = useGreaterThan('md')
  const greaterThanLg = useGreaterThan('lg')
  const greaterThanNav = useGreaterThan('nav')

  return isEdit ? (
    <>
      <div
        className="intersection-observer pointer-events-none absolute left-0 top-[-50px] h-[1000px] max-h-screen w-2"
        ref={navNodeRef}
      />
      <div className={`${stickyObserver}`}>
        <div
          className={`${navActive ? 'light' : ''} ${setStickyNav} ${
            pageUp && pageState.header.isStickyHeader ? revealNavClasses : ''
          }`}
        >
          <div className={navigationWrapperClasses} id="navigation-container">
            <div className={navigationContainerClasses} role="navigation" aria-label="Main">
              <div className={logoWrapperClasses}>
                <Link
                  href="/"
                  className={`${navActive ? 'opacity-0' : 'opacity-100'} xm:opacity-100`}
                  title="ADF Careers"
                >
                  <Image
                    className="block w-[133px] max-w-full md:w-min [.dark_&]:hidden [.grey_&]:hidden [.light_&]:block"
                    src={logoLight}
                    alt="ADF Careers homepage"
                  />
                  <Image
                    className="hidden w-[133px] max-w-full md:w-min [.dark_&]:block [.grey_&]:block [.light_&]:hidden"
                    src={logoDark}
                    alt="ADF Careers homepage"
                  />
                </Link>
              </div>
              <nav className={navigationActionsClasses} aria-label="Action Menu">
                {greaterThanMd && (
                  <>
                    <MainNavAction
                      onMouseOver={deactivateNav}
                      link={props?.fields?.data?.datasource?.shortList?.field}
                    />
                    <MainNavAction onMouseOver={deactivateNav} link={props?.fields?.data?.datasource?.apply?.field} />
                  </>
                )}

                <div className="hidden md:flex">
                  {greaterThanNav && (
                    <MainNavAction onMouseOver={deactivateNav} link={props?.fields?.data?.datasource?.call?.field} />
                  )}
                  {lessThanNav && <MainNavIcon icon={`phone`} link={props?.fields?.data?.datasource?.call?.field} />}
                </div>

                {greaterThanNav && (
                  <MainNavAction onMouseOver={deactivateNav} link={props?.fields?.data?.datasource?.account?.field} />
                )}
                {lessThanNav && <MainNavIcon icon={`user`} link={props?.fields?.data?.datasource?.account?.field} />}

                {!searchActive && (
                  <MainNavIcon
                    onMouseOver={deactivateNav}
                    title={`Search`}
                    icon={`search`}
                    ariaExpanded={false}
                    onClick={() => setSearchActive(!searchActive)}
                  />
                )}

                {searchActive && (
                  <MainNavIcon
                    onMouseOver={deactivateNav}
                    title={`Close`}
                    icon={`close`}
                    ariaExpanded={false}
                    onClick={() => setSearchActive(!searchActive)}
                  />
                )}

                {lessThanLg && (
                  <MainNavIcon
                    title={`Slide out menu`}
                    icon={`menu`}
                    isSelected={navActive}
                    onClick={() => {
                      setNavActive(!navActive)
                    }}
                    ariaExpanded={navActive as boolean}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <FocusTrap
      active={searchActive}
      focusTrapOptions={{
        returnFocusOnDeactivate: false,
        initialFocus: false,
      }}
    >
      <div>
        <div
          className="intersection-observer pointer-events-none absolute left-0 top-[-1px] h-[1000px] w-2"
          ref={navNodeRef}
        />
        <div className={`${stickyObserver}`}>
          <div
            className={`${navActive ? 'light' : ''} ${setStickyNav} ${
              pageUp && pageState?.header.isStickyHeader ? revealNavClasses : ''
            }`}
          >
            <FocusTrap
              active={navActive && !searchActive}
              focusTrapOptions={{
                returnFocusOnDeactivate: false,
                initialFocus: false,
                onActivate: () => {
                  // Blur the current active element if it's not within the navigation
                  const currentActive = document.activeElement as HTMLElement
                  if (currentActive && !focusTrapContainerRef.current?.contains(currentActive)) {
                    currentActive.blur()
                  }
                },
              }}
            >
              <div ref={focusTrapContainerRef}>
                <div className={navigationWrapperClasses} id="navigation-container">
                  <div className={navigationContainerClasses} role="navigation" aria-label="Main">
                    <div className={logoWrapperClasses}>
                      <Link
                        href="/"
                        className={`${navActive ? 'opacity-0' : 'opacity-100'} xm:opacity-100`}
                        title="ADF Careers"
                        onClick={() =>
                          trackNavigation({
                            location: 'header',
                            path: 'logo',
                          })
                        }
                      >
                        <Image
                          className="block w-[133px] max-w-full md:w-min [.dark_&]:hidden [.grey_&]:hidden [.light_&]:block"
                          src={logoLight}
                          alt="ADF Careers homepage"
                        />
                        <Image
                          className="hidden w-[133px] max-w-full md:w-min [.dark_&]:block [.grey_&]:block [.light_&]:hidden"
                          src={logoDark}
                          alt="ADF Careers homepage"
                        />
                      </Link>
                      {lessThanXm && (
                        <div className={logoBackClasses}>
                          <MainNavBack
                            title={secondNav?.navigationTitle?.value || firstNav?.navigationTitle?.value || 'Back'}
                            onClick={() => {
                              if (secondNav) setSecondNav(undefined)
                              else if (firstNav) setFirstNav(undefined)
                              else if (navActive) setNavActive(false)
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {!isEdit && (
                      <div
                        className={navPageMaskClasses}
                        onClick={() => deactivateNav()}
                        onMouseOver={() => deactivateNav()}
                      ></div>
                    )}

                    {/* SSR SEO NAV */}
                    <nav ref={ssrNavRef} className="ssr-navigation-nav hidden">
                      {props?.fields?.data?.item?.children?.results?.map((item, i) => {
                        return (
                          !item?.hideInNavigation?.value?.value && (
                            <ul key={i} role="menu">
                              <li role="none">
                                <a href={item?.url?.path} role="menuitem">
                                  {item?.navigationTitle?.value}
                                </a>
                                {item?.children?.results?.length && (
                                  <ul role="none">
                                    {item?.children?.results?.map((item, i) => {
                                      return (
                                        !item?.hideInNavigation?.value?.value && (
                                          <li key={i} role="none">
                                            <a href={item?.url?.path} role="menuitem">
                                              {item?.navigationTitle?.value}
                                            </a>
                                            {item?.children?.results?.length && (
                                              <ul role="none">
                                                {item?.children?.results?.map((item, i) => {
                                                  return (
                                                    !item?.hideInNavigation?.value?.value && (
                                                      <li key={i} role="none">
                                                        <a href={item?.url?.path} role="menuitem">
                                                          {item?.navigationTitle?.value}
                                                        </a>
                                                      </li>
                                                    )
                                                  )
                                                })}
                                              </ul>
                                            )}
                                          </li>
                                        )
                                      )
                                    })}
                                  </ul>
                                )}
                              </li>
                            </ul>
                          )
                        )
                      })}
                    </nav>

                    {/* DESKTOP SLIDE OUT NAV */}
                    {greaterThanLg && (
                      <nav
                        className="navigation-nav group/nav flex"
                        onMouseLeave={deactivateNav}
                        aria-label="Main Menu"
                      >
                        {props?.fields?.data?.item?.children?.results?.map((item, i) => {
                          return (
                            !item?.hideInNavigation?.value?.value && (
                              <div className="flex items-center" key={i}>
                                <MainNavItem
                                  key={i}
                                  title={item?.navigationTitle}
                                  path={item?.url?.path}
                                  isSelected={
                                    firstNav == item ||
                                    levels.level1 === item?.url?.path?.toLowerCase().replaceAll(' ', '-')
                                  }
                                  onMouseOver={() => {
                                    setNavActive(true)
                                    setFirstNav(item)
                                    setSecondNav(undefined)
                                    setThirdNav(undefined)
                                  }}
                                  onClick={() => setNavActive(false)}
                                />
                                {
                                  !isEdit && item === firstNav && !!firstNav?.children?.results?.length && (
                                    <div
                                      className={firstLevelClasses}
                                      style={subNavContainer}
                                      onMouseLeave={deactivateNav}
                                    >
                                      {firstNav?.children?.results?.map((item, i) => {
                                        return (
                                          !item?.hideInNavigation?.value?.value && (
                                            <div className="group/sub relative" key={i}>
                                              <MainNavSubItem
                                                key={i}
                                                title={item?.navigationTitle}
                                                path={item?.url?.path}
                                                type={'standard'}
                                                isSelected={
                                                  thirdNav == item ||
                                                  levels.level2 === item?.url?.path?.toLowerCase().replaceAll(' ', '-')
                                                }
                                                onMouseOver={() => {
                                                  setFirstSubChild(true)
                                                  setNavActive(true)
                                                  setSubNavActive(hasVisibleChildren(item?.children?.results))
                                                  setSecondNav(item)
                                                  setThirdNav(undefined)
                                                }}
                                                onMouseLeave={deactivateSubNav}
                                                onClick={() => setNavActive(false)}
                                                isParent={hasVisibleChildren(item?.children?.results)}
                                                isHovered={subNavActive && secondNav?.url?.path === item?.url?.path}
                                              />
                                              {hasVisibleChildren(item?.children?.results) && (
                                                <div
                                                  className={secondLevelClasses}
                                                  onMouseEnter={() => setSubNavActive(true)}
                                                >
                                                  {item?.children?.results?.map((subitem, i) => {
                                                    return (
                                                      !subitem?.hideInNavigation?.value?.value && (
                                                        <MainNavSubItem
                                                          key={i}
                                                          title={subitem?.navigationTitle}
                                                          path={subitem?.url?.path}
                                                          type={'standard'}
                                                          isSelected={
                                                            thirdNav == subitem ||
                                                            levels.level3 ===
                                                              subitem?.url?.path?.toLowerCase().replaceAll(' ', '-')
                                                          }
                                                          isSmall={true}
                                                          onMouseOver={() => {
                                                            setFirstSubChild(false)
                                                            setNavActive(true)
                                                            setSubNavActive(true)
                                                            setThirdNav(item)
                                                          }}
                                                          onClick={() => setNavActive(false)}
                                                        />
                                                      )
                                                    )
                                                  })}
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )
                                      })}
                                      <div className={firstQuickLinksClasses}>
                                        {firstNav?.children?.results?.map((item, i) => {
                                          return (
                                            item?.showAsQuickLink?.value?.value && (
                                              <div className={`${quickLinksClasses} ql-${i}`} key={i}>
                                                <QuickLink
                                                  link={{
                                                    value: {
                                                      href: item?.url?.path,
                                                      target: '_self',
                                                      title: item?.pageTitle?.value,
                                                      text: item?.ctaDescription?.value,
                                                    },
                                                  }}
                                                  isServiceTag={false}
                                                  onClick={() =>
                                                    trackNavigation({
                                                      location: 'header',
                                                      path: `quicklink - ${item?.pageTitle?.value || ''}`,
                                                    })
                                                  }
                                                />
                                              </div>
                                            )
                                          )
                                        })}
                                      </div>
                                    </div>
                                  )
                                  // End sub menus
                                }
                              </div>
                            )
                          )
                        })}
                      </nav>
                    )}

                    <nav className={navigationActionsClasses} aria-label="Action Menu">
                      {greaterThanMd && (
                        <>
                          <ShortlistNavAction
                            button
                            onMouseOver={greaterThanLg ? deactivateNav : undefined}
                            link={props?.fields?.data?.datasource?.shortList?.field}
                          />
                          <MainNavAction
                            onMouseOver={greaterThanLg ? deactivateNav : undefined}
                            link={props?.fields?.data?.datasource?.apply?.field}
                          />
                        </>
                      )}

                      <div className="hidden md:flex">
                        {greaterThanNav && (
                          <MainNavAction
                            onMouseOver={deactivateNav}
                            link={props?.fields?.data?.datasource?.call?.field}
                          />
                        )}
                        {lessThanNav && (
                          <MainNavIcon icon={`phone`} link={props?.fields?.data?.datasource?.call?.field} />
                        )}
                      </div>

                      {greaterThanNav && (
                        <MainNavAction
                          onMouseOver={deactivateNav}
                          link={props?.fields?.data?.datasource?.account?.field}
                        />
                      )}
                      {lessThanNav && (
                        <MainNavIcon icon={`user`} link={props?.fields?.data?.datasource?.account?.field} />
                      )}

                      {!searchActive && (
                        <MainNavIcon
                          onMouseOver={deactivateNav}
                          title={`Search`}
                          icon={`search`}
                          ariaExpanded={false}
                          onClick={() => setSearchActive(!searchActive)}
                        />
                      )}

                      {searchActive && (
                        <MainNavIcon
                          onMouseOver={deactivateNav}
                          title={`Close`}
                          icon={`close`}
                          ariaExpanded={false}
                          onClick={() => setSearchActive(!searchActive)}
                        />
                      )}

                      {lessThanLg && (
                        <MainNavIcon
                          title={`Slide out menu`}
                          icon={`menu`}
                          isSelected={navActive}
                          onClick={() => {
                            setNavActive(!navActive)
                          }}
                          ariaExpanded={navActive as boolean}
                        />
                      )}
                    </nav>

                    {/* Mobile navigation */}
                    {lessThanLg && (
                      <div className={navMobileWrapperClasses}>
                        <nav className={navMobileClasses}>
                          <div
                            className={`call-us bg-white-off md:hidden ${navMobileInnerClasses} !pt-0`}
                            {...(firstNav ? { inert: '' } : {})}
                          >
                            <div className="pt-0">
                              <MainNavCall icon={`phone`} link={props?.fields?.data?.datasource?.call?.field} />
                            </div>
                          </div>
                          <div className={`${navMobileInnerClasses} pb-[70px]`}>
                            {props?.fields?.data?.item?.children?.results?.map((item, i) => {
                              return (
                                !item?.hideInNavigation?.value?.value && (
                                  <div className="flex items-center" key={i}>
                                    <MainNavMobile
                                      key={i}
                                      title={item?.navigationTitle}
                                      path={item?.url?.path}
                                      isSelected={
                                        firstNav == item ||
                                        levels.level1 === item?.url?.path?.toLowerCase().replaceAll(' ', '-')
                                      }
                                      onClick={(bool: boolean) => {
                                        setNavActive(bool)
                                        setFirstNav(item)
                                        setSecondNav(undefined)
                                        setThirdNav(undefined)
                                      }}
                                      isParent={!!item?.children?.results?.length}
                                      tabindex={firstNav || secondNav ? -1 : 0}
                                    />
                                    {item === firstNav && !!firstNav?.children?.results?.length && (
                                      <div className={`${navMobileSubClasses}`}>
                                        <div className={navMobileInnerClasses}>
                                          <div className="logo-back hidden py-[18px] xm:block">
                                            <MainNavBack
                                              title={firstNav?.navigationTitle?.value || 'Back'}
                                              onClick={() => setFirstNav(undefined)}
                                            />
                                          </div>
                                          {firstNav?.children?.results?.map((item, i) => {
                                            return (
                                              !item?.hideInNavigation?.value?.value && (
                                                <div key={i}>
                                                  <MainNavMobile
                                                    key={i}
                                                    title={item?.navigationTitle}
                                                    path={item?.url?.path}
                                                    type={'standard'}
                                                    isSelected={
                                                      thirdNav == item ||
                                                      levels.level2 ===
                                                        item?.url?.path?.toLowerCase().replaceAll(' ', '-')
                                                    }
                                                    isSmall={true}
                                                    onClick={(bool: boolean) => {
                                                      setNavActive(bool)
                                                      setSubNavActive(hasVisibleChildren(item?.children?.results))
                                                      setSecondNav(item)
                                                      setThirdNav(undefined)
                                                    }}
                                                    isParent={hasVisibleChildren(item?.children?.results)}
                                                    tabindex={secondNav ? -1 : 0}
                                                  />
                                                  {item === secondNav &&
                                                    hasVisibleChildren(secondNav?.children?.results) && (
                                                      <div className={`${navMobileSubClasses}`}>
                                                        <div className={`${navMobileInnerClasses}`}>
                                                          <div className="logo-back hidden py-[18px] xm:block">
                                                            <MainNavBack
                                                              title={secondNav?.navigationTitle?.value || 'Back'}
                                                              onClick={() => setSecondNav(undefined)}
                                                            />
                                                          </div>
                                                          {item?.children?.results?.map((subitem, i) => {
                                                            return (
                                                              !subitem?.hideInNavigation?.value?.value && (
                                                                <MainNavMobile
                                                                  key={i}
                                                                  title={subitem?.navigationTitle}
                                                                  path={subitem?.url?.path}
                                                                  type={'standard'}
                                                                  isSelected={
                                                                    thirdNav == subitem ||
                                                                    levels.level3 ===
                                                                      subitem?.url?.path
                                                                        ?.toLowerCase()
                                                                        .replaceAll(' ', '-')
                                                                  }
                                                                  isSmall={true}
                                                                  onClick={(bool: boolean) => {
                                                                    setNavActive(bool)
                                                                    setSubNavActive(bool)
                                                                    setThirdNav(item)
                                                                  }}
                                                                />
                                                              )
                                                            )
                                                          })}
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              )
                                            )
                                          })}
                                          <div className={firstQuickLinksClasses}>
                                            {firstNav?.children?.results?.map((item, i) => {
                                              return (
                                                item?.showAsQuickLink?.value?.value && (
                                                  <div className={`${quickLinksClasses} ql-${i}`} key={i}>
                                                    <QuickLink
                                                      link={{
                                                        value: {
                                                          href: item?.url?.path,
                                                          target: '_self',
                                                          title: item?.pageTitle?.value,
                                                          text: item?.ctaDescription?.value,
                                                        },
                                                      }}
                                                      isServiceTag={false}
                                                      onClick={() =>
                                                        trackNavigation({
                                                          location: 'header',
                                                          path: `quicklink - ${item?.pageTitle?.value || ''}`,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                )
                                              )
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )
                            })}
                          </div>
                        </nav>
                        <div className={navMobilePinned}>
                          <MainNavAction link={props?.fields?.data?.datasource?.apply?.field} />

                          <ShortlistNavAction button link={props?.fields?.data?.datasource?.shortList?.field} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative">
                  {!pageState?.errorState.isNotFound && (
                    <div className={breadCrumbsClasses}>
                      <BreadCrumbs fields={props.fields} />
                    </div>
                  )}
                  <div className="search-portal"></div>
                  <div className="profile-tab-desktop"></div>
                  <div className={gradientClasses}></div>
                </div>
              </div>
            </FocusTrap>
          </div>
        </div>
        <div className={headerGapClasses}></div>
        <div className="profile-tab-mobile"></div>
        <SearchTakeover
          searchActive={searchActive}
          onClose={() => {
            setSearchActive(false)
          }}
        />
      </div>
    </FocusTrap>
  )
}

export default ADFNavigation
