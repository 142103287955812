import JobCard from 'components/front-end/JobCard/JobCard'
import { EntryMethod } from 'components/front-end/JobDetails/_JobInterface'
import Notification from 'components/front-end/Notifications/Notification'
import { SHORTLIST_MAX, useShortlist } from 'components/front-end/Shortlist/ShortlistProvider'

const JobsShortlist = (): JSX.Element => {
  const shortlist = useShortlist()

  const shortlistCount = shortlist.items?.length ?? 0
  const andCompare = shortlist.config.hideCompareTool ? "" : " and compare up to 4 at a time"

  return (
    <div>
      <div className="mbody light text-grey-medium">
        <div className="mt-4">
          You have saved {shortlistCount} {shortlistCount == 1 ? "role" : "roles"} to your shortlist.
        </div>
        <div className="mt-4 [&~div]:lg:ml-0">
          {shortlist.isFull ? (
            <Notification
              serviceName="tri-service"
              icon={{ name: 'error', type: 'stroke' }}
              text={`You can save a maximum of ${SHORTLIST_MAX} roles to your shortlist. If you’d like to save an additional role, please remove one first.`}
            />
          ) : (
            <Notification
              isGrey
              icon={{ name: 'info-circle', type: 'stroke' }}
              text={`You can save up to ${SHORTLIST_MAX} roles to your shortlist${andCompare}. When you apply, you’ll be able to nominate your top 3 preferred roles.`}
            />
          )}
        </div>
        {shortlist.cache
          ? [...shortlist.cache]
              .filter(({ id }) => Boolean(id && shortlist.has(id)))
              .reverse()
              .map(({ entryMethods, ...job }) => (
                <JobCard
                  key={job.id}
                  jobDetails={{
                    ...job,
                    entryMethods: entryMethods.map(({ title }: EntryMethod) => title as string),
                    savedButtonText: 'REMOVE',
                  }}
                  displayPriorityTag={true}
                  compact
                />
              ))
          : null}
      </div>
    </div>
  )
}

export default JobsShortlist
