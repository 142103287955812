import React from 'react'
import { Image as JssImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import parse from 'html-react-parser'
import Link from 'next/link'

import { useGreaterThan, useLessThan } from '../../../utils/useBreakpoints'
import { SearchResult } from 'components/ADFSearchResults'
import Button, { ButtonProps } from 'components/front-end/Buttons/Button'
import ImageLoader from 'components/front-end/Images/ImageLoader'
import ImageSwiper from 'components/front-end/Images/ImageSwiper'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import StandardTagList from 'components/front-end/Tags/StandardTagList'
import { cleanHeadingStyle } from 'src/utils/cleanHeadingStyle'
import DateTag, { DateTagProps } from '../Tags/DateTag'
import { Tag } from '../Tags/StandardTag'

export interface CardProps extends SearchResult {
  children?: React.ReactNode
  className?: string
  subtitle?: string
  headingLevel?: keyof React.JSX.IntrinsicElements
  headingAriaLevel?: number
  tags?: (Tag | string)[]
  dateTag?: DateTagProps
  buttons?: ButtonProps[]
  imageWidth?: number
  imageHeight?: number
}

const Card = ({
  service,
  image,
  imageWidth = 772,
  imageHeight = 596,
  title,
  headingLevel = 'h6',
  headingAriaLevel = 3,
  copy,
  id,
  cardType = 'full',
  children,
  className,
  subtitle,
  tags,
  dateTag,
  buttons,
}: CardProps) => {
  const HeadingLevel = cleanHeadingStyle(headingLevel)
  const { sitecoreContext } = useSitecoreContext()

  const themeClasses = `
    text-black [.light_&]:text-black [.dark_&]:!text-white [.dark_.light_&]:!text-black
    ${className || ''}
  `

  const cardLeftClasses = `
  relative span-12 md-span-6 xl-span-5
  `
  const cardRightClasses = `
  ${cardType === 'full' ? 'span-12 md-span-6 xl-span-7' : ''}
  ${cardType === 'compact' ? 'span-12' : ''}
  ${cardType === 'match' ? 'span-12' : ''}
  ${cardType === 'feature' ? 'span-12  md-span-6 xl-span-7' : ''}
  `
  const cardRightInnerClasses = `
    ${cardType === 'full' ? '!py-4 md:!pl-6 xl:!pl-8' : ''}
    ${cardType === 'compact' ? '!py-3' : ''}
    ${cardType === 'match' ? '!py-3' : ''}
    ${cardType === 'feature' ? '!py-5 md:!pl-6 xl:!pl-8 xl:!pr-7' : ''}
  `

  const cardClasses = `
    ${cardType === 'simple' ? 'p-10 ' : ' py-10 border-b border-grey-light dark:border-grey-medium lg:ml-10'}`

  // If buttons exist, get the link of first item, e.g. "View Role", for title and image
  const firstButtonLink = buttons && buttons[0].link.value.href
  const images = Array.isArray(image) ? image : ([image].filter(Boolean) as string[])

  return (
    <div className={`relative ${themeClasses} ${cardClasses}`} data-id={id} data-type={cardType}>
      <div className="row flex-wrap">
        {cardType !== 'compact' && (
          <div className={cardLeftClasses}>
            {dateTag && (
              <DateTag
                {...dateTag}
                className="absolute right-[calc(var(--gutter)/2)] top-0 z-10 mt-3 md:left-[calc(var(--gutter)/2)] md:right-auto"
              />
            )}

            {images?.length === 1 ? (
              firstButtonLink ? (
                <Link href={firstButtonLink || ''} tabIndex={-1}>
                  {sitecoreContext?.pageEditing ? (
                    <JssImage className="w-full" height={imageHeight} width={imageWidth} field={{ src: images[0] }} />
                  ) : (
                    <ImageLoader
                      full
                      height={imageHeight}
                      width={imageWidth}
                      src={images[0]}
                      alt={title || ''}
                      sizes="(max-width: 767px) 100vw, (max-width: 1439px) 50vw, 40vw"
                    />
                  )}
                </Link>
              ) : (
                <ImageLoader full height={imageHeight} width={imageWidth} src={images[0]} alt={title || ''} />
              )
            ) : firstButtonLink ? (
              <Link href={firstButtonLink || ''} tabIndex={-1}>
                <ImageSwiper full>
                  {images?.map((src, i) => (
                    <ImageLoader
                      full
                      key={`${src}-${i}`}
                      height={imageHeight}
                      width={imageWidth}
                      src={src}
                      alt={title || ''}
                      sizes="(max-width: 767px) 100vw, (max-width: 1439px) 50vw, 40vw"
                    />
                  ))}
                </ImageSwiper>
              </Link>
            ) : (
              <ImageSwiper full>
                {images?.map((src, i) => (
                  <ImageLoader
                    full
                    key={`${src}-${i}`}
                    height={imageHeight}
                    width={imageWidth}
                    src={src}
                    alt={title || ''}
                    sizes="(max-width: 767px) 100vw, (max-width: 1439px) 50vw, 40vw"
                  />
                ))}
              </ImageSwiper>
            )}
          </div>
        )}
        <div className={cardRightClasses}>
          <div className={cardRightInnerClasses}>
            <div className={`mb-6 flex justify-between ${cardType === 'feature' ? 'xm:mb-10' : ''}`}>
              {service && <ServiceTag isCard service={service} />}
              {tags && useGreaterThan('xm') && <StandardTagList tags={tags} />}
            </div>
            {subtitle && <p className="sbody mb-3 font-uniform-condensed uppercase">{subtitle}</p>}

            <HeadingLevel
              className={`h6 mb-2 ${cardType === 'feature' ? 'lg:h5 lg:mb-3' : ''}`}
              aria-level={headingAriaLevel}
            >
              {firstButtonLink ? (
                <Link href={firstButtonLink || ''} className="hover:underline" tabIndex={-1}>
                  {title}
                </Link>
              ) : (
                title
              )}
            </HeadingLevel>

            {tags && useLessThan('xm') && <StandardTagList tags={tags} className="mb-4 mt-3.5" />}

            <p className={`mbody ${cardType === 'feature' ? 'lg:lbody' : ''}`} dangerouslySetInnerHTML={{ __html: copy || ""}}></p>

            {children}

            <div className={`mt-6 flex flex-wrap xm:flex-nowrap ${cardType === 'feature' ? 'lg:mt-8' : ''}`}>
              {cardType === 'feature' && (
                <Button
                  data-trackingid={id as string}
                  link={{
                    value: {
                      href: '',
                      target: '_blank',
                      text: 'See Highights',
                    },
                  }}
                  icon="chevron-right-double"
                  service={service || 'tri-service'}
                  isSmall
                  type="primary"
                />
              )}
              <div className={`flex flex-wrap gap-4`}>
                {buttons?.map((b, i) => <Button key={i} {...b} service={service} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
