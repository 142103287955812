import { MouseEventHandler, useEffect } from 'react'

import { SearchResult } from 'components/ADFSearchResults'
import { ButtonProps } from '../Buttons/Button'
import Card from '../Cards/Card'
import { shortlistButtonProps } from '../Shortlist/ShortlistButton'
import { useShortlist } from '../Shortlist/ShortlistProvider'
import { Tag } from '../Tags/StandardTag'
import { useMachine } from '@xstate/react'
import JobCardMachine from 'src/machines/JobCardMachine'

type JobCardProps = {
  jobDetails: SearchResult
  query?: string
  displayPriorityTag?: boolean
  compact?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  buttons?: ButtonProps[]
}

export function tagList(result: SearchResult, displayPriorityTag?: boolean) {
  const jobListingTags: Tag[] = []
  const isPriorityJob = displayPriorityTag && result.isPriority

  //PRIORITY ROLE or ROLE IS FULL tag,
  // if is priority and also as full - display ROLE IS FULL tag only
  // if is priority and is not full - display the PRIORITY ROLE tag only
  // if is full and not a priority - display the ROLE IS FULL tag only
  if (result.isClosed) {
    jobListingTags.push({ text: result.isClosedText ?? 'Role is full', type: 'closed' })
  } else if (isPriorityJob) {
    jobListingTags.push({ text: 'PRIORITY ROLE', type: 'priority' })
  }

  //GENERAL ENTRY or OFFICER ENTRY or GAP YEAR tag
  if (result.isGapYear) {
    jobListingTags.push({ text: 'GAP YEAR' })
  } else if (result.isOfficer) {
    jobListingTags.push({ text: 'OFFICER ENTRY' })
  } else {
    jobListingTags.push({ text: 'GENERAL ENTRY' })
  }

  //FULL TIME or RESERVE tag
  if (result.isFulltime) {
    jobListingTags.push({ text: 'FULL TIME' })
  } else if (result.isReserve) {
    jobListingTags.push({ text: 'RESERVE' })
  } else {
    jobListingTags.push({ text: 'FULL TIME' })
  }
  return jobListingTags
}

const JobCard = ({ jobDetails, query, displayPriorityTag, compact, buttons: buttonProps, onClick }: JobCardProps) => {
  const shortlist = useShortlist()
  const [{context}, send] = useMachine(JobCardMachine)

  if (!jobDetails?.id) {
    return null
  }

  useEffect(() => {
    send({type: 'SYNC_STATE', query, rawCopy: jobDetails?.copy})
  }, [jobDetails?.copy, query])

  const id = jobDetails.id
  const tags = tagList(jobDetails, displayPriorityTag)
  const { cssClass: service, image: imageProp, ...props } = jobDetails
  const imagePaths = Array.isArray(imageProp) ? imageProp : imageProp ? [imageProp] : null
  const image = imagePaths?.map((imgPath) => `${process.env.HUB_API_HOST}${imgPath}`)

  const cardType = compact ? 'compact' : 'full'
  const saved = shortlist.has(id)
  const inShortlistDrawer = jobDetails.savedButtonText?.match(/remove/i)
  const trackingId = inShortlistDrawer ? `shortlist-${id}` : id

  const buttons: ButtonProps[] = buttonProps ?? [
    {
      'data-trackingid': trackingId,
      'data-jobname': jobDetails.title as string,
      'data-jobtype': jobDetails.isPriority ? 'priority to fill' : '',
      link: { value: { href: `${jobDetails.url}`, text: 'View Role' } },
      icon: 'chevron-right-double',
      type: 'small-secondary',
      className: 'scroll-mt-80',
      onClick,
    },
    shortlistButtonProps({
      id: trackingId,
      'data-jobname': jobDetails.title as string,
      'data-jobtype': jobDetails.isPriority ? 'priority to fill' : '',
      saved,
      label: {
        saved: jobDetails.savedButtonText,
        unsaved: jobDetails.unsavedButtonText,
      },
      onClick: () => {
        const added = shortlist.toggle(id)
        if (added) {
          shortlist.setShowShortlist(added)
        }
      },
    }),
  ]

  const cardProps = { cardType, service, image, tags, buttons, headingAriaLevel: 2, ...props, copy: context.copy }
  return (
    <Card {...cardProps}>
      <p className="xsbody mb-1 mt-8 font-bold">Ways to enter role</p>
      <p className={`xsbody mb-2 ${jobDetails.cardType === 'feature' ? 'xm:mb-8' : ''}`}>
        {jobDetails.entryMethods?.map((method, i) => (
          <span className="[span+&]:before:content-[',_']" key={`${method}-${i}`}>
            {method}
          </span>
        ))}
      </p>
    </Card>
  )
}

export default JobCard
